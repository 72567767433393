import React from 'react'
import './PrivacyPolicy.css'
import Footer from '../../components/footer/footer'
import { Mail, MapPin } from 'lucide-react'

const PrivacyPolicy = () => {
	return (
		<div className="privacy-policy">
			{/* Background gradient SVGs */}
			<svg
				className="blob-left-top"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#gradient-left-top)"
				/>
				<defs>
					<linearGradient
						id="gradient-left-top"
						x1="45"
						y1="301.5"
						x2="817.5"
						y2="301.5"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#533E95" />
						<stop offset="1" stopColor="#784FFF" />
					</linearGradient>
				</defs>
			</svg>

			<svg
				className="blob-center"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#gradient-center)"
				/>
				<defs>
					<linearGradient id="gradient-center" x1="45" y1="301.5" x2="817.5" y2="301.5" gradientUnits="userSpaceOnUse">
						<stop stopColor="#533E95" />
						<stop offset="1" stopColor="#784FFF" />
					</linearGradient>
				</defs>
			</svg>

			<div className="privacy-container">
				<h1>Polityka Prywatności</h1>

				<section>
					<h2>1. Administrator danych</h2>
					<p>
						Administratorem danych osobowych jest Antek Blonkowski, BLONEKDEV, Mławka 25, 13-230 Iłowo-Osada. Kontakt:
						blonekdev@gmail.com.
					</p>
				</section>

				<section>
					<h2>2. Gromadzone dane</h2>
					<p>Gromadzimy wyłącznie adresy e-mail użytkowników:</p>
					<ul>
						<li>
							Podanie danych jest dobrowolne, ale konieczne do realizacji zamówień (np. dostarczenie zakupionych
							ebooków).
						</li>
						<li>
							Dane osobowe są przetwarzane zgodnie z przepisami prawa, w szczególności zgodnie z Rozporządzeniem
							Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO).
						</li>
					</ul>
				</section>

				<section>
					<h2>3. Cele przetwarzania danych</h2>
					<ul>
						<li>Realizacja zamówień – wysyłka zakupionych ebooków na podany adres e-mail.</li>
						<li>Utrzymanie kontaktu z klientem – w sprawach związanych z zakupem.</li>
						<li>Zarządzanie kontem użytkownika (jeśli dostępne).</li>
						<li>Cele statystyczne i analityczne – poprawa jakości usług.</li>
					</ul>
				</section>

				<section>
					<h2>4. Prawa użytkowników</h2>
					<ul>
						<li>Prawo dostępu do danych – użytkownik ma prawo wiedzieć, jakie dane są przetwarzane i w jaki sposób.</li>
						<li>Prawo do sprostowania danych – możliwość poprawienia błędnych danych.</li>
						<li>Prawo do usunięcia danych – prawo do żądania usunięcia danych („prawo do bycia zapomnianym").</li>
						<li>Prawo do ograniczenia przetwarzania.</li>
						<li>Prawo do sprzeciwu wobec przetwarzania danych.</li>
						<li>Prawo do przenoszenia danych.</li>
					</ul>
					<p>
						W celu realizacji powyższych praw należy skontaktować się z administratorem danych: blonekdev@gmail.com.
					</p>
				</section>

				<section>
					<h2>5. Płatności</h2>
					<p>
						Płatności w sklepie internetowym są realizowane za pośrednictwem operatora HotPay. Administrator nie
						przechowuje danych dotyczących płatności, które są przetwarzane zgodnie z polityką prywatności operatora.
					</p>
				</section>

				<section>
					<h2>6. Polityka zwrotów</h2>
					<p>
						Zgodnie z obowiązującymi przepisami,{' '}
						<strong>sklep BLONEKDEV nie przyjmuje zwrotów zakupionych ebooków</strong>, ponieważ są to produkty cyfrowe
						dostarczane w formie niematerialnej.
					</p>
				</section>

				<section>
					<h2>7. Pliki Cookies</h2>
					<p>Sklep internetowy BLONEKDEV wykorzystuje pliki cookies w celu:</p>
					<ul>
						<li>Umożliwienia poprawnego działania serwisu.</li>
						<li>Analizy ruchu na stronie.</li>
						<li>Poprawy funkcjonalności i komfortu użytkowania.</li>
					</ul>
					<p>Użytkownicy mogą zarządzać ustawieniami cookies za pośrednictwem ustawień przeglądarki.</p>
				</section>

				<section>
					<h2>8. Zmiany Polityki Prywatności</h2>
					<p>
						Administrator zastrzega sobie prawo do aktualizacji niniejszej Polityki Prywatności w razie potrzeby. Zmiany
						zostaną opublikowane na stronie sklepu.
					</p>
				</section>

				<section className="contact-section">
					<h2>9. Kontakt</h2>
					<p>W przypadku pytań lub wątpliwości dotyczących Polityki Prywatności prosimy o kontakt:</p>
					<div className="contact-info">
						<div className="contact-item">
							<Mail size={20} />
							<span>blonekdev@gmail.com</span>
						</div>
						<div className="contact-item">
							<MapPin size={20} />
							<span>Mławka 25, 13-230 Iłowo-Osada, Polska</span>
						</div>
					</div>
				</section>

				<div className="policy-footer">
					<p>
						<strong>Data wejścia w życie: 01.01.2024</strong>
					</p>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default PrivacyPolicy
