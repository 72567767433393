import React from 'react'
import { Link } from 'react-router-dom'
import { Mail, Home, FileText, Shield } from 'lucide-react'
import hotpayLogo from '../../assets/system-hotpay-white.png'
import './footer.css'

export default function Footer() {
	const currentYear = new Date().getFullYear()

	return (
		<footer className="footer">
			<div className="footer-container">
				<div className="footer-content">
					<div className="footer-logo">
						<h3>Titan Method</h3>
						<p>Najszybsza droga do osiągnięcia wymarzonej sylwetki</p>
					</div>

					<nav className="footer-nav">
						<Link to="/" className="footer-link">
							<Home size={18} />
							<span>Strona główna</span>
						</Link>

						<Link to="/polityka-prywatnosci" className="footer-link">
							<Shield size={18} />
							<span>Polityka Prywatności</span>
						</Link>

						<Link to="/" className="footer-link">
							<FileText size={18} />
							<span>Regulamin</span>
						</Link>
					</nav>

					<div className="footer-contact">
						<a href="mailto:titanmethodebook@gmail.com" className="footer-link">
							<Mail size={18} />
							<span>titanmethodebook@gmail.com</span>
						</a>
					</div>
				</div>

				<div className="payment-info">
					<a href="https://hotpay.pl" target="_blank" rel="noopener noreferrer" className="payment-logo">
						<img src={hotpayLogo} alt="HotPay - operator płatności" />
					</a>
				</div>

				<div className="footer-bottom">
					<div className="copyright">
						<p>&copy; {currentYear} BLONEKDEV. Wszelkie prawa zastrzeżone.</p>
					</div>

					<div className="footer-social">{/* Add social media links if needed */}</div>
				</div>
			</div>
		</footer>
	)
}
